import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { CanActivateChildGuard } from 'app/core/auth/guards/can-activate-child.gaurd';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver, UserAssetResolver, DepartmentResolver, PreLoadContentResolver } from 'app/app.resolvers';
import { environment } from 'environments/environment';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'login' },
    // Auth routes for guests
    // {
    //     path: '',
    //     // canActivate: [NoAuthGuard],
    //     // canActivateChild: [NoAuthGuard],
    //     component: LayoutComponent,
    //     data: {
    //         layout: 'empty'
    //     },
    //     children: [
    //         { path: 'login', loadChildren: () => import('app/modules/admin/login/login.module').then(m => m.AuthLoginModule) },
    //         { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule) },
    //         { path: 'forgot-password', loadChildren: () => import('app/modules/admin/login/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule) },
    //         { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule) },
    //         { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule) },
    //         { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule) },
    //         { path: 'testurl', loadChildren: () => import('app/modules/admin/testurl/testurl.module').then(m => m.AuthtesturlModule) },
    //         { path: 'testimageupload', loadChildren: () => import('app/modules/admin/testimageupload/testimageupload.module').then(m => m.AuthtestimageuploadModule) },

    //     ]
    // },

    // Auth routes for authenticated users
    {
        path: '',
        // canActivate:  [AuthGuard],
        // canActivateChild: [CanActivateChildGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'login', loadChildren: () => import('app/modules/admin/login/login.module').then(m => m.AuthLoginModule) },
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule) },

            { path: 'forgot-password', loadChildren: () => import('app/modules/admin/login/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule) },

        ]
    },




    // Admin routes
    {
        path: '',
        // canActivate: environment.AuthEnable ? [AuthGuard]: [],
        // canActivateChild: environment.AuthEnable ? [CanActivateChildGuard] : [],
        component: LayoutComponent,
        data: {
            layout: 'modern'
        },
        resolve: {
            userAsset: UserAssetResolver,
            initialData: InitialDataResolver,
            Department: DepartmentResolver,
            // Preloaddata: PreLoadContentResolver
        },
        children: [
            { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule) },
            { path: 'previewquestions/:id', loadChildren: () => import('app/modules/admin/new-dashboard/preview-questions/preview-questions.module').then(m => m.PreviewQuestionsModule) },
            { path: 'testurl', loadChildren: () => import('app/modules/admin/testurl/testurl.module').then(m => m.AuthtesturlModule) },
            { path: 'company', loadChildren: () => import('app/modules/admin/company/company.module').then(m => m.companyModule) },
            { path: 'newdashboard', loadChildren: () => import('app/modules/admin/new-dashboard/new-dashboard.module').then(m => m.NewDashboardModule) },
            { path: 'changepassword', loadChildren: () => import('app/modules/admin/changepassword/changepassword.module').then(m => m.changepasswordModule) },
            { path: 'editprofile', loadChildren: () => import('app/modules/admin/editprofile/editprofile.module').then(m => m.editprofileModule) },
            { path: 'media', loadChildren: () => import('app/modules/admin/media/media.module').then(m => m.mediaModule) },
            { path: 'usermedia', loadChildren: () => import('app/modules/admin/media/media.module').then(m => m.mediaModule) },
            // { path: 'newmedia', loadChildren: () => import('app/modules/admin/media copy/newmedia.module').then(m => m.newmediaModule) },

            { path: 'question', loadChildren: () => import('app/modules/admin/question/question.module').then(m => m.questionModule) },
            { path: 'quiz', loadChildren: () => import('app/modules/admin/course/course.module').then(m => m.CourseModule) },
            { path: 'user', loadChildren: () => import('app/modules/admin/usermanagement/usermanagement.module').then(m => m.UserManagementModule) },
            { path: 'comingsoon', loadChildren: () => import('app/modules/admin/maintenance/maintenance.module').then(m => m.MaintenanceModule) },
            // { path: 'department', loadChildren: () => import('app/modules/admin/department/department.module').then(m => m.DepartmentModule) },
            { path: 'element', loadChildren: () => import('app/modules/admin/module/module.module').then(m => m.ModuleModule) },
            { path: 'class', loadChildren: () => import('app/modules/admin/class/class.module').then(m => m.ClassModule) },
            { path: 'team', loadChildren: () => import('app/modules/admin/teamuser/teamuser.module').then(m => m.teamuserModule) },
            { path: 'learningmodule', loadChildren: () => import('app/modules/admin/learning-module/learning-module.module').then(m => m.LearningModuleModule) },
            { path: 'learningmodulelive', loadChildren: () => import('app/modules/admin/learning-module-live/learning-module-live.module').then(m => m.LearningModuleLiveModule) },
            { path: 'interactivesessions', loadChildren: () => import('app/modules/admin/interactive-sessions/interactive-sessions.module').then(m => m.InteractiveSessionsModule) },
            { path: 'interactivesessionslive', loadChildren: () => import('app/modules/admin/interactive-sessions-live/interactive-sessions-live.module').then(m => m.InteractiveSessionsLiveModule) },
            { path: 'case', loadChildren: () => import('app/modules/admin/case/case.module').then(m => m.CaseModule) },
            { path: 'bundle', loadChildren: () => import('app/modules/admin/bundle/bundle.module').then(m => m.BundleModule) },
            { path: 'keywords', loadChildren: () => import('app/modules/admin/keywords/keywords.module').then(m => m.KeywordsModule) },
            { path: 'banner', loadChildren: () => import('app/modules/admin/banner/banner.module').then(m => m.BannerModule) },
            // { path: 'testurl', loadChildren: () => import('app/modules/admin/testurl/testurl.module').then(m => m.AuthtesturlModule) },
            { path: 'registration', loadChildren: () => import('app/modules/admin/registration/registration.module').then(m => m.RegistrationModule) },
            { path: 'demo1', loadChildren: () => import('app/modules/admin/demo1/demo1.module').then(m => m.Demo1Module) },
            // { path: 'testimageupload', loadChildren: () => import('app/modules/admin/testimageupload/testimageupload.module').then(m => m.AuthtestimageuploadModule) },
        ]
    },
    {
        path: '',
        // canActivate: environment.AuthEnable ? [AuthGuard]: [],
        // canActivateChild: environment.AuthEnable ? [CanActivateChildGuard] : [],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'leaderboard/:id', loadChildren: () => import('app/modules/admin/leaderboard/leaderboard.module').then(m => m.LeaderboardModule) },

        ]

    },  {
        path: '',
        // canActivate: environment.AuthEnable ? [AuthGuard]: [],
        // canActivateChild: environment.AuthEnable ? [CanActivateChildGuard] : [],
        component: LayoutComponent,
        data: {
            layout: 'custom'
        },
        children: [
            { path: 'dashboard/:id', loadChildren: () => import('app/modules/admin/new-dashboard/new-dashboard.module').then(m => m.NewDashboardModule) }
        ]

    },

    // 404 & Catch all
    { path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/modules/admin/error/error-404/error-404.module').then(m => m.Error404Module) },
    { path: '**', redirectTo: '404-not-found' }
];


