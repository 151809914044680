import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';

import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';

import { MatDatepickerModule } from '@angular/material/datepicker';

import { MatMomentDateModule } from '@angular/material-moment-adapter';

import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRippleModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmationDialog } from './dialog/dialog.component';
import { TermsConfirmationDialog } from './terms-dialog/terms-dialog.component';
import { AlertService } from './alert.service';

import { MatSidenavModule } from '@angular/material/sidenav';
import { FuseAutogrowModule } from '@fuse/directives/autogrow';
import { FuseFindByKeyPipeModule } from '@fuse/pipes/find-by-key';
import { MatBadgeModule } from '@angular/material/badge';
import { MatChipsModule } from '@angular/material/chips';



import { QuillModule } from 'ngx-quill';
import { NgxMatNativeDateModule, NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { DragDropModule } from "@angular/cdk/drag-drop";


import { SwiperModule } from "swiper/angular";
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { StlModelViewerModule } from 'angular-stl-model-viewer';


import { NgApexchartsModule } from 'ng-apexcharts';

import { TeamListComponent } from './team-list/team-list.component';
import { MediaViewerComponent } from './common/media-viewer/media-viewer.component';
import { VenueComponent } from './common/venue/venue.component';
import { FileListComponent } from './common/file-list/file-list.component';
import { ParticipantTeamListComponent } from './common/participant-team-list/participant-team-list.component';
import { QuestionPreviewComponent } from './common/question-preview/question-preview.component';
import { ProfileViewComponent } from './common/profile-view/profile-view.component';
import { KeywordListComponent } from './common/keyword-list/keyword-list.component';
import { ImagekitioAngularModule } from 'imagekitio-angular';
import { environment } from 'environments/environment';


@NgModule({
    declarations: [ConfirmationDialog, TermsConfirmationDialog, TeamListComponent, VenueComponent, FileListComponent, MediaViewerComponent, ParticipantTeamListComponent, QuestionPreviewComponent, ProfileViewComponent, KeywordListComponent],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        MatStepperModule,

        MatTableModule,
        MatPaginatorModule,
        MatSortModule,

        MatDatepickerModule,
        MatMomentDateModule,
        MatProgressBarModule,
        MatRippleModule,
        MatSlideToggleModule,
        MatTooltipModule,

        MatSnackBarModule,
        MatDialogModule,
        MatSidenavModule,
        FuseAutogrowModule,
        FuseFindByKeyPipeModule,
        MatBadgeModule,
        MatChipsModule,
        QuillModule.forRoot(),
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatNativeDateModule,
        DragDropModule,
        NgApexchartsModule,



        SwiperModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
        NgxDocViewerModule,
        StlModelViewerModule,
        ImagekitioAngularModule.forRoot({
            publicKey: environment.IK_Imagekit_publicKey,
            urlEndpoint: environment.IK_Imagekit_urlEndpoint,
            authenticationEndpoint: environment.IK_Imagekit_authenticationEndpoint
          })
        
    ],
    exports: [

        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        KeywordListComponent,

        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        MatStepperModule,

        MatTableModule,
        MatPaginatorModule,
        MatSortModule,

        MatDatepickerModule,
        MatMomentDateModule,
        MatProgressBarModule,
        MatRippleModule,
        MatSlideToggleModule,
        MatTooltipModule,

        MatSnackBarModule,
        MatDialogModule,
        MatSidenavModule,
        FuseAutogrowModule,
        FuseFindByKeyPipeModule,
        MatBadgeModule,
        MatChipsModule,
        QuillModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatNativeDateModule,

        DragDropModule,
        NgApexchartsModule,

        TeamListComponent,
        VenueComponent,

        SwiperModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
        NgxDocViewerModule,
        StlModelViewerModule
    ],
    providers: [AlertService, DatePipe, DecimalPipe,
        {
            provide: MAT_RADIO_DEFAULT_OPTIONS,
            useValue: { color: 'accent' },
        }]
})
export class SharedModule {
}
