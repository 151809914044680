import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { AlertService } from '../shared/alert.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import moment from 'moment';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private _httpClient: HttpClient,
    private _alert: AlertService,
  ) { }

EventID: number=0;
EventElementID='-1';
EventName: string='';
ViewUserID: number=0;

  // Declaration
  public Scheme: BehaviorSubject<any> = new BehaviorSubject('light');
  public TeamList: BehaviorSubject<any> = new BehaviorSubject([]);
  public StateList: BehaviorSubject<any> = new BehaviorSubject([]);
  public VenueList: BehaviorSubject<any> = new BehaviorSubject([]);
  public venueData: BehaviorSubject<any> = new BehaviorSubject({});
  public ParticipantTeamList: BehaviorSubject<any> = new BehaviorSubject([]);
  public QuestionOptionChartData: BehaviorSubject<any> = new BehaviorSubject([]);
  public UserDetail: BehaviorSubject<any> = new BehaviorSubject({});
  private ProfessionList: BehaviorSubject<any> = new BehaviorSubject([]);
  private QuestionMedia: BehaviorSubject<any> = new BehaviorSubject([]);
  private AnswerData: BehaviorSubject<any> = new BehaviorSubject([]);
  private ThemeList: BehaviorSubject<any> = new BehaviorSubject([]);
  public KeywordDropdownList: BehaviorSubject<any> = new BehaviorSubject([]);

  //Accessor
  get Scheme$(): Observable<any> {
    return this.Scheme.asObservable();
  }
  get TeamList$(): Observable<any> {
    return this.TeamList.asObservable();
  }

  get StateList$(): Observable<any> {
    return this.StateList.asObservable();
  }

  get VenueList$(): Observable<any> {
    return this.VenueList.asObservable();
  }

  get venueData$(): Observable<any> {
    return this.venueData.asObservable();
  }

  get ParticipantTeamList$(): Observable<any> {
    return this.ParticipantTeamList.asObservable();
  }

  get QuestionOptionChartData$(): Observable<any> {
    return this.QuestionOptionChartData.asObservable();
  }

  get UserDetail$(): Observable<any> {
    return this.UserDetail.asObservable();
  }
  get ProfessionList$(): Observable<any> {
    return this.ProfessionList.asObservable();
  }

  get QuestionMedia$(): Observable<any> {
    return this.QuestionMedia.asObservable();
  }


  get AnswerData$(): Observable<any> {
    return this.AnswerData.asObservable();
  }

  get ThemeList$(): Observable<any> {
    return this.ThemeList.asObservable();
  }

  get KeywordDropdownList$(): Observable<any> {
    return this.KeywordDropdownList.asObservable();
  }
  // Methods

  SetSchemen(mode) {
    this.Scheme.next(mode);
  }

  getTeamList(SelectedTeam?, Batch: number = -1):
    Observable<any> {
    const obj = { "UserID": localStorage.getItem("UserID"), "Device": localStorage.getItem("Device"), 'Batch': Batch, "CompanyID": localStorage.getItem("CompanyID") }
    if (SelectedTeam?.length) {
      Object.assign(obj, { SelectedTeam: SelectedTeam });
    }
    return this._httpClient.post<any>(environment.apiUrl, { MethodName: 'GetTeam', InputStr: [obj] }).pipe(
      tap((data) => {
        let jsonStr1 = "";
        if (Object.keys(data).length > 0) {
          for (let i = 0; i < data["Table"].length; i++) {
            jsonStr1 += data["Table"][i][environment.SQLJsonString];
          }
          let jsonobj1 = JSON.parse(jsonStr1);
          if (jsonobj1.Result[0]["ErrorID"] > 0) {
            let jsonStr2: any = "";
            for (let i = 0; i < data["Table1"].length; i++) {
              jsonStr2 += data["Table1"][i][environment.SQLJsonString];
            }
            this.TeamList.next(JSON.parse(jsonStr2).Result);
          }
          else {
            this.TeamList.next([]);
            this._alert.show(jsonobj1.Result[0]["ErrorMessage"])
          }
        }
        else {
          this.TeamList.next([]);
          this._alert.show('Oops! Something Went Wrong')
        }
      })
    )

  }



  getKeywordDropdownList():
    Observable<any> {
    console.log("shared")
    const obj = { "UserID": localStorage.getItem('UserID'), "Device": localStorage.getItem('Device'), "DepartmentID": localStorage.getItem('DepartmentID') }
    return this._httpClient.post<any>(environment.apiUrl, { MethodName: 'GetKeywordsList', InputStr: [obj] }).pipe(
      tap((data) => {

        let jsonStr1 = "";
        if (Object.keys(data).length > 0) {
          for (let i = 0; i < data["Table"].length; i++) {
            jsonStr1 += data["Table"][i][environment.SQLJsonString];
          }
          let jsonobj1 = JSON.parse(jsonStr1);
          if (jsonobj1.Result[0]["ErrorID"] > 0) {
            let jsonStr2: any = "";
            for (let i = 0; i < data["Table1"].length; i++) {
              jsonStr2 += data["Table1"][i][environment.SQLJsonString];
            }
            this.KeywordDropdownList.next(JSON.parse(jsonStr2).Result);
            // console.log(JSON.parse(jsonStr2).Result)
          }
          else {
            this.KeywordDropdownList.next([]);
            //this._alert.show(jsonobj1.Result[0]["ErrorMessage"])
          }
        }
        else {
          this.KeywordDropdownList.next([]);
          this._alert.show('Oops! Something Went Wrong')
        }
      })
    )

  }

  getStateList():
    Observable<any> {
    const obj = { "UserID": localStorage.getItem("UserID"), "Device": localStorage.getItem("Device"), }
    return this._httpClient.post<any>(environment.apiUrl, { MethodName: 'GetStateView', InputStr: [obj] }).pipe(
      tap((data) => {
        // console.log(data);
        let jsonStr1 = "";
        if (Object.keys(data).length > 0) {
          for (let i = 0; i < data["Table"].length; i++) {
            jsonStr1 += data["Table"][i][environment.SQLJsonString];
          }
          let jsonobj1 = JSON.parse(jsonStr1);
          if (jsonobj1.Result[0]["ErrorID"] > 0) {
            let jsonStr2: any = "";
            for (let i = 0; i < data["Table1"].length; i++) {
              jsonStr2 += data["Table1"][i][environment.SQLJsonString];
            }
            this.StateList.next(JSON.parse(jsonStr2).Result);

          }
          else {
            this.StateList.next([]);
            this._alert.show(jsonobj1.Result[0]["ErrorMessage"])
          }
        }
        else {
          this.StateList.next([]);
          this._alert.show('Oops! Something Went Wrong')
        }
      })
    )

  }

  getVenueList():
    Observable<any> {
    const obj = { "UserID": localStorage.getItem("UserID"), "Device": localStorage.getItem("Device"), "CompanyID": localStorage.getItem("CompanyID") }
    return this._httpClient.post<any>(environment.apiUrl, { MethodName: 'GetVenue', InputStr: [obj] }).pipe(
      tap((data) => {
        // console.log(data);
        let jsonStr1 = "";
        if (Object.keys(data).length > 0) {
          for (let i = 0; i < data["Table"].length; i++) {
            jsonStr1 += data["Table"][i][environment.SQLJsonString];
          }
          let jsonobj1 = JSON.parse(jsonStr1);
          if (jsonobj1.Result[0]["ErrorID"] > 0) {
            let jsonStr2: any = "";
            for (let i = 0; i < data["Table1"].length; i++) {
              jsonStr2 += data["Table1"][i][environment.SQLJsonString];
            }
            this.VenueList.next(JSON.parse(jsonStr2).Result);
          }
          else {
            this.VenueList.next([]);
            if (jsonobj1.Result[0]["ErrorMessage"] != 'No Record Found') {
              this._alert.show(jsonobj1.Result[0]["ErrorMessage"])
            }
          }
        }
        else {
          this.VenueList.next([]);
          this._alert.show('Oops! Something Went Wrong')
        }
      })
    )

  }
  getVenueDataById(id: string): Observable<any> {
    const obj = { "UserID": localStorage.getItem('UserID'), "Device": localStorage.getItem('Device'), "CompanyID": localStorage.getItem("CompanyID"), "VenueID": id }
    return this._httpClient.post<any>(environment.apiUrl, { MethodName: 'GetVenueDetail', InputStr: [obj] }).pipe(
      tap((data) => {
        let jsonStr1 = "";
        // console.log(data);
        if (Object.keys(data).length > 0) {
          for (let i = 0; i < data["Table"].length; i++) {
            jsonStr1 += data["Table"][i][environment.SQLJsonString];
          }
          let jsonobj1 = JSON.parse(jsonStr1);
          if (jsonobj1.Result[0]["ErrorID"] > 0) {
            let Obj: any = {};
            let jsonStr2: any = "";
            for (let i = 0; i < data["Table1"].length; i++) {
              jsonStr2 += data["Table1"][i][environment.SQLJsonString];
            }
            this.venueData.next(JSON.parse(jsonStr2).Result[0]);
          }
          else {
            this.venueData.next({});
            this._alert.show(jsonobj1.Result[0]["ErrorMessage"])
          }
        }
        else {
          this.venueData.next({});
          this._alert.show('Oops! Something Went Wrong')
        }
      })
    );

  }

  updateVenueData(data): Observable<any> {
    return this._httpClient.post(environment.apiUrl, { MethodName: 'SaveVenue', InputStr: [data] }).pipe(
      map((updateddata) => {
        let response = {
          message: '',
          navigateId: null,
        };
        let jsonStr1 = "";
        if (Object.keys(updateddata).length > 0) {
          for (let i = 0; i < updateddata["Table"].length; i++) {
            jsonStr1 += updateddata["Table"][i][environment.SQLJsonString];
          }
          let jsonobj1 = JSON.parse(jsonStr1);
          if (jsonobj1.Result[0]["ErrorID"] > 0) {

            this.getVenueList().subscribe();
            this._alert.show(jsonobj1.Result[0]["ErrorMessage"])
          }
          else {
            this._alert.show(jsonobj1.Result[0]["ErrorMessage"])
            response.message = jsonobj1.Result[0]["ErrorMessage"];
          }
        }
        else {
          this._alert.show('Oops! Something Went Wrong')
        }
        return response;
      })
    )
  }

  deleteVenueData(id) {
    const obj = { UserID: localStorage.getItem('UserID'), Device: localStorage.getItem('Device'), VenueID: id };
    // // console.log({ MethodName: 'DeleteForm', InputStr: [obj] });
    return this._httpClient.post(environment.apiUrl, { MethodName: 'DeleteVenue', InputStr: [obj] }).pipe(
      map((data) => {
        // // console.log(data)
        let errorMessage = '';
        let jsonStr1 = "";
        if (Object.keys(data).length > 0) {
          for (let i = 0; i < data["Table"].length; i++) {
            jsonStr1 += data["Table"][i][environment.SQLJsonString];
          }
          let jsonobj1 = JSON.parse(jsonStr1);
          if (jsonobj1.Result[0]["ErrorID"] > 0) {
            this.getVenueList().subscribe();
            this._alert.show(jsonobj1.Result[0]["ErrorMessage"])
          }
          else {
            errorMessage = jsonobj1.Result[0]["ErrorMessage"];
            this._alert.show(jsonobj1.Result[0]["ErrorMessage"])
          }
        }
        else {
          this._alert.show('Oops! Something Went Wrong')
        }
        return errorMessage;
      }))
  }

  getParticipantTeamList(EventID, ThemeID):
    Observable<any> {
    const obj = { "UserID": localStorage.getItem("UserID"), "Device": localStorage.getItem("Device"), "EventID": EventID, "EventElementID": '-1', "ThemeID": ThemeID }
    return this._httpClient.post<any>(environment.apiUrl, { MethodName: 'GetEventLeaderBoardResult', InputStr: [obj] }).pipe(
      tap((data) => {
        let jsonStr1 = "";
        if (Object.keys(data).length > 0) {
          for (let i = 0; i < data["Table"].length; i++) {
            jsonStr1 += data["Table"][i][environment.SQLJsonString];
          }
          let jsonobj1 = JSON.parse(jsonStr1);
          if (jsonobj1.Result[0]["ErrorID"] > 0) {
            let jsonStr2: any = "";
            for (let i = 0; i < data["Table1"].length; i++) {
              jsonStr2 += data["Table1"][i][environment.SQLJsonString];
            }
            this.ParticipantTeamList.next(JSON.parse(jsonStr2).Result);
          }
          else {
            this.ParticipantTeamList.next([]);
            this._alert.show(jsonobj1.Result[0]["ErrorMessage"])
          }
        }
        else {
          this.ParticipantTeamList.next([]);
          this._alert.show('Oops! Something Went Wrong')
        }
      })
    )

  }

  getQuestionOptionChart(EventID, EventElementID, QuestionID, ThemeID, ProfessionID, processAction?):
    Observable<any> {
    const obj = { "UserID": localStorage.getItem("UserID"), "Device": localStorage.getItem("Device"), "EventID": EventID, "EventElementID": EventElementID, "QuestionID": QuestionID, "ThemeID": ThemeID, "ProfessionID": ProfessionID, "Process": "" }
    processAction ? obj.Process = processAction : delete obj.Process;
    return this._httpClient.post<any>(environment.apiUrl, { MethodName: 'GetEventQuestionResult', InputStr: [obj] }).pipe(
      map((data) => {
        let errorMessage = '';
        let jsonStr1 = "";
        if (Object.keys(data).length > 0) {
          if (data["Table"]?.length) {
            for (let i = 0; i < data["Table"].length; i++) {
              jsonStr1 += data["Table"][i][environment.SQLJsonString];
            }
            let jsonobj1 = JSON.parse(jsonStr1);
            if (jsonobj1.Result[0]["ErrorID"] > 0) {
              let jsonStr2: any = "";
              for (let i = 0; i < data["Table1"].length; i++) {
                jsonStr2 += data["Table1"][i][environment.SQLJsonString];
              }
              // console.log(JSON.parse(jsonStr2).Result)
              this.QuestionOptionChartData.next(JSON.parse(jsonStr2).Result);
            }
          }
          else {
            this.QuestionOptionChartData.next([]);
          } if (data["Table5"]?.length && processAction) {
            let jsonStr2: any = "";
            for (let i = 0; i < data["Table5"].length; i++) {
              jsonStr2 += data["Table5"][i][environment.SQLJsonString];
            }
            this.ThemeList.next(JSON.parse(jsonStr2).ThemeList);
          } else if (processAction) {
            this.ThemeList.next([]);
          } if (data["Table3"]?.length) {
            let jsonStr2: any = "";
            for (let i = 0; i < data["Table3"].length; i++) {
              jsonStr2 += data["Table3"][i][environment.SQLJsonString];
            }
            this.AnswerData.next(JSON.parse(jsonStr2).AnswerData);
          } else {
            this.AnswerData.next([]);
          } if (data["Table4"]?.length && processAction) {
            let jsonStr2: any = "";
            for (let i = 0; i < data["Table4"].length; i++) {
              jsonStr2 += data["Table4"][i][environment.SQLJsonString];
            }
            this.ProfessionList.next(JSON.parse(jsonStr2).ProfessionList);
          } else if (processAction) {
            this.ProfessionList.next([]);
          } if (data["Table2"]?.length) {
            let jsonStr2: any = "";
            for (let i = 0; i < data["Table2"].length; i++) {
              jsonStr2 += data["Table2"][i][environment.SQLJsonString];
            }
            this.QuestionMedia.next(JSON.parse(jsonStr2).QuestionMedia);
          } else {
            this.QuestionMedia.next([]);
          }
        } else {
          this.QuestionOptionChartData.next([]);
          this.ThemeList.next([]);
          this.ProfessionList.next([]);
          this.AnswerData.next([]);
          this.QuestionMedia.next([]);
          this._alert.show('Oops! Something Went Wrong')
        }
        return errorMessage;
      })
    )

  }


  GetUserDetail(id: string, basicInfo?: string): Observable<any> {
    const obj = { "UserID": localStorage.getItem('UserID'), "Device": localStorage.getItem('Device'), "CompanyID": localStorage.getItem('CompanyID'), "EditUserID": id, "Process": "" }
    basicInfo ? obj.Process = basicInfo : delete obj.Process;
    return this._httpClient.post<any>(environment.apiUrl, { MethodName: 'GetUserDetail', InputStr: [obj] }).pipe(
      tap((data) => {
        // console.log(data)
        let jsonStr1 = "";
        if (Object.keys(data).length > 0) {
          for (let i = 0; i < data["Table"].length; i++) {
            jsonStr1 += data["Table"][i][environment.SQLJsonString];
          }
          let jsonobj1 = JSON.parse(jsonStr1);
          if (jsonobj1.Result[0]["ErrorID"] > 0) {
            let jsonStr2: any = "";
            for (let i = 0; i < data["Table1"].length; i++) {
              jsonStr2 += data["Table1"][i][environment.SQLJsonString];
            }
            this.UserDetail.next(JSON.parse(jsonStr2).Result[0]);
          }
          else {
            this.UserDetail.next({});
            this._alert.show(jsonobj1.Result[0]["ErrorMessage"])
          }
        }
        else {
          this.UserDetail.next({});
          this._alert.show('Oops! Something Went Wrong')
        }
      })
    );

  }

  WrongExportData(EventID, EventElementID, Process,ViewUserID) {
    const obj = {
        "UserID": localStorage.getItem('UserID'), "Device": localStorage.getItem('Device'), "EventID": EventID, "EventElementID": EventElementID, "Process": Process ,
        "ViewUserID":ViewUserID
    }
   
    return this._httpClient.post(environment.apiUrl, { MethodName: 'GetEventFinalResult', InputStr: [obj] }).pipe(
        map((data) => {
            let returnValue = [];
            let jsonStr1 = '';
            if (Object.keys(data).length > 0) {
                for (let i = 0; i < data["Table"].length; i++) {
                    jsonStr1 += data["Table"][i][environment.SQLJsonString];
                }
                let jsonobj1 = JSON.parse(jsonStr1);
                if (jsonobj1.Result[0]["ErrorID"] > 0) {
                    if (data["Table1"]?.length) {
                        let jsonStr2: any = "";
                        for (let i = 0; i < data["Table1"].length; i++) {
                            jsonStr2 += data["Table1"][i][environment.SQLJsonString];
                        }
                        returnValue = (JSON.parse(jsonStr2)).Result;
                    }
                }
            }
            return returnValue;
        })
    )
}
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_' +moment(new Date()).format("MM/DD/YYYY")  + EXCEL_EXTENSION);
  }

}
